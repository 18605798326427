.head-date-sections {
  padding: 100px 0px;
}
.head-date1 {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px 20px;
    width: 200px;
    gap: 10px;
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    background-color: white;
    justify-content: space-around;
}
.head-date3>h1{
    font-size: 20px;
    letter-spacing: 2px;
}
.w-11{
    width:100%;
    display: flex;
    justify-content: center;
}
.w-12{
    width:90%;
}
.wrp-date-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex-direction: column;
    align-content: center;
}