
    .dashboard-bg{
        background: url("../../Img/20210204_a547.jpg");
        background-position: center;
        background-size: cover;
        /* height: 100vh; */
    }
    
    .side-dash1{
        display: flex;
        width: 100%;
    }
    .card-1-dash>img{
        width: 343px;
        border-radius: 15px;
        height: auto;
    }
    .head-dash{
    padding: 71px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    }
 
    .dash-logo-1>img{
        width: calc(100% - 4px);
        border: 1px solid;
    }
    .dash-a2{

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .dash-h1{

        font-size: 20px;
        padding: 10px 0px;
        text-align: center;
    }

    .dash-p1{
    font-size: 17px;
    letter-spacing: 2px;
    text-align: center;

    }


    @media only screen and (max-width: 600px) {
        .dash-a2 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
    

    }
    @media only screen and (max-width: 900px) {


    .dash-a3{
        width: 100% !important;
    }
    }


    .dash-a3{
        width: 60%;
    }
    .i-f-dash-1{
        width: 100%;
    }
    .dash-a4{
        padding: 20px 0px;
        display: flex;
        justify-content: center;
    }
    .dash-h2{

        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
    }
    .dash-a5{
        padding: 20px 0px;
    }

    .dash-a6>h1{
        font-size: 26px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding: 10px 0px;
    }

    .dash-7{

        display: flex;
        align-items: center;
        justify-content: center;

    }
    .dash1 {
        border-bottom: 1px solid red;
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    }
    .dash2{
        border-bottom: transparent;
    }
    .dash-btn1{
        border: 1px solid white;
        padding: 10px 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border: 1px solid white;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;

        
    }

    .dash-btn-1{
        background-color: #bc0000;

        padding: 10px 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        color:white;
    }


    .dash-btn2{
        border: 1px solid white;
        padding: 10px 20px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;
    }

    .dash-btn-2{
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        border: 1px solid white;
        padding: 10px 20px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #bc0000;

        color:white;
    }


    .dash-btn3{
        border: 1px solid white;
        padding: 10px 20px;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;
    }

    .dash-btn-3{
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        border: 1px solid white;
        padding: 10px 20px;
        background-color: #bc0000;

        color:white;
    }


    /*  card */
    .dashboard-bg{
        background-image: url("../../Img/20210204_a547.jpg");
        background-position: center;
        background-size: cover;
        /* height: 100vh; */
    }
    
    .side-dash1{
        display: flex;
        width: 100%;
    }
    .card-1-dash>img{
        width: 343px;
        border-radius: 15px;
        height: auto;
    }
    .head-dash{
    padding: 71px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    }
  
    .dash-logo-1>img{
        width: calc(100% - 4px);
        border: 1px solid;
    }
    .dash-a2{

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .dash-h1{

        font-size: 20px;
        padding: 10px 0px;
        text-align: center;
    }

    .dash-p1{
    font-size: 17px;
    letter-spacing: 2px;
    text-align: center;

    }


    @media only screen and (max-width: 600px) {
        .dash-a2 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
        .dash-a6>h1 {
            font-size: 15px !important;
        
        }

    }
    @media only screen and (max-width: 900px) {


    .dash-a3{
        width: 100% !important;
    }
    .dash-a31{
        width: 100% !important;


    }
    .i-f-dash-2 {
        width: 100%;
        height: 50vw !important;
    }
    }

    .react-player__preview{
    border-radius: 15px !important;
    }


    .dash-a3{
        width: 60%;
    }


    .dash-a31{
        width: 60%;

    }
    .i-f-dash-1{
        width: 100%;
        /* height:; */
    }
    .i-f-dash-2{
        width: 100%;
        height:29vw;
    }
    .dash-a4{
        padding: 20px 0px;
        display: flex;
        justify-content: center;
    }
    .dash-h2{

        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
    }
    .dash-a5{
        padding: 20px 0px;
    }

    .dash-a6>h1{
        font-size: 26px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding: 10px 0px;
    }

    .dash-7{

        display: flex;
        align-items: center;
        justify-content: center;

    }
    .dash1 {
        border-bottom: 1px solid red;
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    }
    .dash2{
        border-bottom: transparent;
    }
    .dash-btn1{
        border: 1px solid white;
        padding: 10px 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border: 1px solid white;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;

        
    }

    .dash-btn-1{
        background-color: #bc0000;

        padding: 10px 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        color:white;
    }


    .dash-btn2{
        border: 1px solid white;
        padding: 10px 20px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;
    }

    .dash-btn-2{
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        border: 1px solid white;
        padding: 10px 20px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #bc0000;

        color:white;
    }


    .dash-btn3{
        border: 1px solid white;
        padding: 10px 20px;
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        color: #bc0000;
    }

    .dash-btn-3{
        /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
        border: 1px solid white;
        padding: 10px 20px;
        background-color: #bc0000;

        color:white;
    }


    /*  card */




    /* height: 5rem;
        text-transform: uppercase;
    
        

        color: #fff;
        clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%); */


        .wrapper1{
            max-width: 1090px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            justify-content: space-evenly;
        }
        
        .wrapper1 .table-1{
            background: #fff;
            /* width: calc(33% - 20px); */
            width: fit-content;
            padding: 30px 30px;
            position: relative;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-radius: 15px;
        }
        
        @media (max-width: 1020px){
            .wrapper .table-1{
                /* width: calc(50% - 20px); */
                margin-bottom: 40px;
            }
        }
        
        @media (max-width: 698px){
         
        }
        
        .table-1 .price-section-1{
        display: flex;
        justify-content: center;
        }
        
        .price-section-1 .price-area-1{
            height: 120px;
            width: 120px;
            /* background: #ffd861; */
            border-radius: 50%;
            padding: 2px;
        }
        
        .price-section-1 .price-area-1 .inner-area-1{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid #fff;
            color: #fff;
            line-height: 117px;
            text-align: center;
            position: relative;
        }
        
        .price-area-1 .inner-area-1 .dash-text{
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            /* top: -10px; */
            left: 12px;
        }
        
        .price-area-1 .inner-area-1 .price{
            font-size: 23px;
            font-weight: 500;
        }
        
        .table-1 .package-name{
            width: 100%;
            height: 2px;
            background: #ffecb3;
            margin: 35px 0;
            position: relative;
        }
        
        .table-1 .package-name::before{
            position: absolute;
            content: "INR";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            font-size: 25px;
            padding: 0 10px;
            font-weight: bolder;
        }
    
        
        .table-1 .features li{
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        
        .features li .list-name{
            font-size: 17px;
            font-weight: 400;
        }
        
        .features li .icon{
            font-size: 15px;
        }
        
        .features li .icon.check{
            color: #bc0000;
        }
        
        .features li .icon.cross{
            color: #cd3241;
        }
        
        .table-1 .btn{
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
        
        .table-1 .btn button{
            /* width: 73%; */
            padding: 8px 29px;
            /* height: 50px; */
            font-weight: 700;
            color: #fff;
            font-size: 20px;
            border: none;
            outline: none;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        
        
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
        
        .Ultimate .btn button{
            background: #fff;
            color: #bc0000;
            border: 2px solid #bc0000;
        }
        
        .Ultimate .btn button:hover{
            /* border-radius: 6px; */
            background: #bc0000;
            color: #fff;
        }
        
        
        .Ultimate .package-name{
            background: #bc0000;
        }
        
    
        
        
        .Ultimate ::selection,
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
        .dash-h8{

            font-size: 26px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 5px;
            padding: 20px 0px;
        }
        /* card 2 */
    
        .wrapper1 .table-2{
            background: #fff;
            /* width: calc(33% - 20px); */
            width: fit-content;
            padding: 30px 30px;
            position: relative;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-radius: 15px;
        }
        
        @media (max-width: 1020px){
            .wrapper .table-2{
                /* width: calc(50% - 20px); */
                margin-bottom: 40px;
            }
        }
        
        @media (max-width: 698px){
         
        }
        
        .table-2 .price-section-2{
        display: flex;
        justify-content: center;
        }
        
        .price-section-2 .price-area-2{
            height: 120px;
            width: 120px;
            /* background: #ffd861; */
            border-radius: 50%;
            padding: 2px;
        }
        
        .price-section-2 .price-area-2 .inner-area-2{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid #fff;
            color: #fff;
            line-height: 117px;
            text-align: center;
            position: relative;
        }
        
        .price-area-2 .inner-area-2 .dash-text{
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            /* top: -10px; */
            left: 25px;
        }
        
        .price-area-2 .inner-area-2 .price-2dash{
            font-size: 23px;
            font-weight: 500;
        }
        
        .table-2 .package-name-2{
            width: 100%;
            height: 2px;
            background: #ffecb3;
            margin: 35px 0;
            position: relative;
        }
        
        .table-2 .package-name-2::before{
            position: absolute;
            content: "USD";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            font-size: 25px;
            padding: 0 10px;
            font-weight: bolder;
        }
    
        
        .table-2 .features-2 li{
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        
        .features-2 li .list-name{
            font-size: 17px;
            font-weight: 400;
        }
        
        .features-2 li .icon{
            font-size: 15px;
        }
        
        .features-2 li .icon.check{
            color: #bc0000;
        }
        
        .features-2 li .icon.cross{
            color: #cd3241;
        }
        
        .table-2 .btn{
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
        
        .table-2 .btn button{
            /* width: 73%; */
            padding: 8px 29px;
            /* height: 50px; */
            font-weight: 700;
            color: #fff;
            font-size: 20px;
            border: none;
            outline: none;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        
        
        .Ultimate .price-area-2,
        .Ultimate .inner-area-2{
            background: #bc0000;
        }
        
        .Ultimate .btn button{
            background: #fff;
            font-size: 15px;
            color: #bc0000;
            border: 2px solid #bc0000;
        }
        
        .Ultimate .btn button:hover{
            /* border-radius: 6px; */
            background: #bc0000;
            color: #fff;
        }
        
        
        .Ultimate .package-name-2{
            background: #bc0000;
        }
        
    
        
        
        .Ultimate ::selection,
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
    
        .dash-h8{
        
            font-size: 26px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 5px;
            padding: 20px 0px;
        }
        .dash-15{
            padding-bottom: 100px;
        }


        /*  */



        
        



        /*  */
        .dash-h15{
            text-transform: uppercase;
            font-size: 43px;
            font-weight: bolder;
            letter-spacing: 3px;
            color: black;
        }
        .row-dash1{
            display: flex;
            justify-content: center;
        }
        .col-lg-8 {
            width: 70%;
        }
        /* .dash-a2{
            background-image: url('../../Img/home-bg-1.png');
            width: 100%;
        } */
    .dash-h10{
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 0px;
    }
    .dash-h11{
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 0px;
    }


    /* card 3 emi */


    .wrapper1{
        max-width: 1090px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        justify-content: space-evenly;
    }

    .wrapper1 .table-3{
        background: #fff;
        /* width: calc(33% - 20px); */
        width: fit-content;
        padding: 30px 30px;
        position: relative;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        border-radius: 15px;
    }

    @media (max-width: 1020px){
        .wrapper .table-3{
            /* width: calc(50% - 20px); */
            margin-bottom: 40px;
        }
    }

    @media (max-width: 698px){
      
    }

    .table-3 .price-section-3{
    display: flex;
    justify-content: center;
    }

    .price-section-3 .price-area-3{
        height: 120px;
        width: 120px;
        /* background: #ffd861; */
        border-radius: 50%;
        padding: 2px;
    }

    .price-section-3 .price-area-3 .inner-area-3{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 3px solid #fff;
        color: #fff;
        line-height: 117px;
        text-align: center;
        position: relative;
    }

    .price-area-3 .inner-area-3 .dash-text{
        font-size: 25px;
        font-weight: 400;
        position: absolute;
        /* top: -10px; */
        left: 12px;
    }

    .price-area-3 .inner-area-3 .price-3dash{
        font-size: 23px;
        font-weight: 500;
    }

    .table-3 .package-name-3{
        width: 100%;
        height: 2px;
        background: #ffecb3;
        margin: 35px 0;
        position: relative;
    }

    .table-3 .package-name-3::before{
        position: absolute;
        content: "INR";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        font-size: 25px;
        padding: 0 10px;
        font-weight: bolder;
    }


    .table-3 .features-3 li{
        list-style: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .features-3 li .list-name{
        font-size: 17px;
        font-weight: 400;
    }

    .features-3 li .icon{
        font-size: 15px;
    }

    .features-3 li .icon.check{
        color: #bc0000;
    }

    .features-3 li .icon.cross{
        color: #cd3241;
    }

    .table-3 .btn{
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    .table-3 .btn button{
        /* width: 73%; */
        padding: 8px 29px;
        /* height: 50px; */
        font-weight: 700;
        color: #fff;
        font-size: 20px;
        border: none;
        outline: none;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
    }


    .Ultimate .price-area-3,
    .Ultimate .inner-area-1{
        background: #bc0000;
    }

    .Ultimate .btn button{
        background: #fff;
        color: #bc0000;
        border: 2px solid #bc0000;
    }

    .Ultimate .btn button:hover{
        /* border-radius: 6px; */
        background: #bc0000;
        color: #fff;
    }


    .Ultimate .package-name-3{
        background: #bc0000;
    }




    .Ultimate ::selection,
    .Ultimate .price-area-3,
    .Ultimate .inner-area-1{
        background: #bc0000;
    }

    .dash-a16{


        width: 100%;
        display: flex;
        justify-content: center;

        
    }

    /*  */




    /* emi details */





    /*  */
    .dash-a15{
        width: 90%;
    }


    @media (max-width: 600px){


    .head-dash {
        padding: 47px 0px 0px 0px;

    }

    }




    /* key features */



    .dash-h12{
        font-size: 26px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding: 20px 0px;
    }

    /* .list-key-features>li{
        list-style-type: disc;
    } */

    .main-container {
        padding: 30px;
    }
    
    /* HEADING */
    
    .heading {
        text-align: center;
    }
    
    .heading__title {
        font-weight: 600;
    }
    
    .heading__credits {
        margin: 10px 0px;
        color: #888888;
        font-size: 25px;
        transition: all 0.5s;
    }
    
    .heading__link {
        text-decoration: none;
    }
    
    .heading__credits .heading__link {
        color: inherit;
    }
    
    /* CARDS */
    

    
    /* CARD BACKGROUNDS */
    
    .card-1 {
        background: #bc0000;
    }
    
    .card-2 {
        background:  #bc0000;
    }
    
    .card-3 {
        background:  #bc0000;
    }
    
    .card-4 {
        background:  #bc0000;
    }
    
    .card-5 {
        background:  #bc0000;
    }
    
    /* RESPONSIVE */
    
    @media (max-width: 1600px) {
        .cards {
        justify-content: center;
        }
    }
    .h2-im-n{
        color: #bc0000;
    }
    .emi-time-line-section{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;;
    }







    .btn-1-a{

        border: 1px solid;
        padding: 15px 15px;
        border-top-left-radius: 20px;
        font-size: 15px;
        border-bottom-left-radius: 20px;
        color:white;
        background-color: #bc0000;
        border: 1px solid white;

    } 
    .btn-2-a{
        color:white;
        background-color: #bc0000;

        border: 1px solid;
        padding: 15px 15px;
        font-size: 15px;
        border: 1px solid white;

    } 
    .btn-3-a{
        color:white;
        background-color: #bc0000;

        border: 1px solid;
        padding: 15px 15px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        font-size: 15px;
        border: 1px solid white;

    } 
    .btn-act1{
        padding: 15px 15px;
        border-top-left-radius: 20px;
        font-size: 15px;
        border-bottom-left-radius: 20px;
    color: #bc0000;
    box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;

    } 
    .btn-act2{
        padding: 15px 15px;
        font-size: 15px;

    color: #bc0000;
    box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;

    } 
    .btn-act3{
        padding: 15px 15px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        font-size: 15px;

    color: #bc0000;
    box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;

    } 

    @media (max-width: 360px){


        .btn-1-a{

            border: 1px solid;
            padding: 15px 15px;
            border-top-left-radius: 20px;
            font-size: 15px;
            border-bottom-left-radius: 20px;
            color:white;
            background-color: #bc0000;
            border: 1px solid white;
        
        } 
        .btn-2-a{
            color:white;
            background-color: #bc0000;
        
            border: 1px solid;
            padding: 6px 15px;

            font-size: 15px;
            border: 1px solid white;
        
        } 
        .btn-3-a{
            color:white;
            background-color: #bc0000;
        
            border: 1px solid;
            padding: 15px 15px;

            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            font-size: 15px;
            border: 1px solid white;
        
        } 
    
        .btn-act1{
            padding: 15px 15px;
            border-top-left-radius: 20px;
            font-size: 15px;
            border-bottom-left-radius: 20px;
        color: #bc0000;
        box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
        
        } 
        .btn-act2{
            padding: 6px 15px;
            font-size: 15px;
        
        color: #bc0000;
        box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
        
        } 
        .btn-act3{        padding: 15px 15px;

            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            font-size: 15px;
        
        color: #bc0000;
        box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
        
        } 
    
        
        }
        @media (max-width: 340px){
            .btn-3-a{
                color:white;
                background-color: #bc0000;
            
                border: 1px solid;
                padding: 6px 15px;
        
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                font-size: 15px;
                border: 1px solid white;
            
            } 


            .btn-act3{        padding:6px 15px;

                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                font-size: 15px;
            
            color: #bc0000;
            box-shadow: rgb(204 219 232) 3px 3px 6px 0px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
            
            } 
        
        }




    /* height: 5rem;
        text-transform: uppercase;
    
        

        color: #fff;
        clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%); */


        .wrapper1{
            max-width: 1090px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            justify-content: space-evenly;
        }
        
        .wrapper1 .table-1{
            background: #fff;
            /* width: calc(33% - 20px); */
            width: fit-content;
            padding: 30px 30px;
            position: relative;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-radius: 15px;
        }
        
        @media (max-width: 1020px){
            .wrapper .table-1{
                /* width: calc(50% - 20px); */
                margin-bottom: 40px;
            }
        }
        
        @media (max-width: 698px){
          
        }
        
        .table-1 .price-section-1{
        display: flex;
        justify-content: center;
        }
        
        .price-section-1 .price-area-1{
            height: 120px;
            width: 120px;
            /* background: #ffd861; */
            border-radius: 50%;
            padding: 2px;
        }
        
        .price-section-1 .price-area-1 .inner-area-1{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid #fff;
            color: #fff;
            line-height: 117px;
            text-align: center;
            position: relative;
        }
        
        .price-area-1 .inner-area-1 .dash-text{
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            /* top: -10px; */
            left: 12px;
        }
        
        .price-area-1 .inner-area-1 .price{
            font-size: 23px;
            font-weight: 500;
        }
        
        .table-1 .package-name{
            width: 100%;
            height: 2px;
            background: #ffecb3;
            margin: 35px 0;
            position: relative;
        }
        
        .table-1 .package-name::before{
            position: absolute;
            content: "INR";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            font-size: 25px;
            padding: 0 10px;
            font-weight: bolder;
        }
    
        
        .table-1 .features li{
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        
        .features li .list-name{
            font-size: 17px;
            font-weight: 400;
        }
        
        .features li .icon{
            font-size: 15px;
        }
        
        .features li .icon.check{
            color: #bc0000;
        }
        
        .features li .icon.cross{
            color: #cd3241;
        }
        
        .table-1 .btn{
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
        
        .table-1 .btn button{
            /* width: 73%; */
            padding: 8px 29px;
            /* height: 50px; */
            font-weight: 700;
            color: #fff;
            font-size: 20px;
            border: none;
            outline: none;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        
        
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
        
        .Ultimate .btn button{
            background: #fff;
            color: #bc0000;
            border: 2px solid #bc0000;
        }
        
        .Ultimate .btn button:hover{
            /* border-radius: 6px; */
            background: #bc0000;
            color: #fff;
        }
        
        
        .Ultimate .package-name{
            background: #bc0000;
        }
        
    
        
        
        .Ultimate ::selection,
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
        .dash-h8{

            font-size: 26px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 5px;
            padding: 20px 0px;
        }
        /* card 2 */
    
        .wrapper1 .table-2{
            background: #fff;
            /* width: calc(33% - 20px); */
            width: fit-content;
            padding: 30px 30px;
            position: relative;
            box-shadow: 0 5px 10px rgba(0,0,0,0.1);
            border-radius: 15px;
        }
        
        @media (max-width: 1020px){
            .wrapper .table-2{
                /* width: calc(50% - 20px); */
                margin-bottom: 40px;
            }
        }
        
        @media (max-width: 698px){
         
        }
        
        .table-2 .price-section-2{
        display: flex;
        justify-content: center;
        }
        
        .price-section-2 .price-area-2{
            height: 120px;
            width: 120px;
            /* background: #ffd861; */
            border-radius: 50%;
            padding: 2px;
        }
        
        .price-section-2 .price-area-2 .inner-area-2{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid #fff;
            color: #fff;
            line-height: 117px;
            text-align: center;
            position: relative;
        }
        
        .price-area-2 .inner-area-2 .dash-text{
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            /* top: -10px; */
            left: 25px;
        }
        
        .price-area-2 .inner-area-2 .price-2dash{
            font-size: 23px;
            font-weight: 500;
        }
        
        .table-2 .package-name-2{
            width: 100%;
            height: 2px;
            background: #ffecb3;
            margin: 35px 0;
            position: relative;
        }
        
        .table-2 .package-name-2::before{
            position: absolute;
            content: "USD";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            font-size: 25px;
            padding: 0 10px;
            font-weight: bolder;
        }
    
        
        .table-2 .features-2 li{
            list-style: none;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }
        
        .features-2 li .list-name{
            font-size: 17px;
            font-weight: 400;
        }
        
        .features-2 li .icon{
            font-size: 15px;
        }
        
        .features-2 li .icon.check{
            color: #bc0000;
        }
        
        .features-2 li .icon.cross{
            color: #cd3241;
        }
        
        .table-2 .btn{
            display: flex;
            justify-content: center;
            margin-top: 35px;
        }
        
        .table-2 .btn button{
            /* width: 73%; */
            padding: 8px 29px;
            /* height: 50px; */
            font-weight: 700;
            color: #fff;
            font-size: 20px;
            border: none;
            outline: none;
            border-radius: 25px;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        
        
        .Ultimate .price-area-2,
        .Ultimate .inner-area-2{
            background: #bc0000;
        }
        
        .Ultimate .btn button{
            background: #fff;
            font-size: 15px;
            color: #bc0000;
            border: 2px solid #bc0000;
        }
        
        .Ultimate .btn button:hover{
            /* border-radius: 6px; */
            background: #bc0000;
            color: #fff;
        }
        
        
        .Ultimate .package-name-2{
            background: #bc0000;
        }
        
    
        
        
        .Ultimate ::selection,
        .Ultimate .price-area-1,
        .Ultimate .inner-area-1{
            background: #bc0000;
        }
    
        .dash-h8{
        
            font-size: 26px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 5px;
            padding: 20px 0px;
        }
        .dash-15{
            padding-bottom: 100px;
        }


        /*  */



        
        



        /*  */
        .dash-h15{
            text-transform: uppercase;
            font-size: 43px;
            font-weight: bolder;
            letter-spacing: 3px;
            color: black;
        }
        .row-dash1{
            display: flex;
            justify-content: center;
        }
        .col-lg-8 {
            width: 70%;
        }
        /* .dash-a2{
            background-image: url('../../Img/home-bg-1.png');
            width: 100%;
        } */
    .dash-h10{
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 0px;
    }
    .dash-h11{
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        padding: 20px 0px;
    }


    /* card 3 emi */


    .wrapper1{
        max-width: 1090px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        justify-content: space-evenly;
    }

    .wrapper1 .table-3{
        background: #fff;
        /* width: calc(33% - 20px); */
        width: fit-content;
        padding: 30px 30px;
        position: relative;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        border-radius: 15px;
    }

    @media (max-width: 1020px){
        .wrapper .table-3{
            /* width: calc(50% - 20px); */
            margin-bottom: 40px;
        }
    }

    @media (max-width: 698px){
    
    }

    .table-3 .price-section-3{
    display: flex;
    justify-content: center;
    }

    .price-section-3 .price-area-3{
        height: 120px;
        width: 120px;
        /* background: #ffd861; */
        border-radius: 50%;
        padding: 2px;
    }

    .price-section-3 .price-area-3 .inner-area-3{
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 3px solid #fff;
        color: #fff;
        line-height: 117px;
        text-align: center;
        position: relative;
    }

    .price-area-3 .inner-area-3 .dash-text{
        font-size: 25px;
        font-weight: 400;
        position: absolute;
        /* top: -10px; */
        left: 12px;
    }

    .price-area-3 .inner-area-3 .price-3dash{
        font-size: 23px;
        font-weight: 500;
    }

    .table-3 .package-name-3{
        width: 100%;
        height: 2px;
        background: #ffecb3;
        margin: 35px 0;
        position: relative;
    }

    .table-3 .package-name-3::before{
        position: absolute;
        content: "INR";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        font-size: 25px;
        padding: 0 10px;
        font-weight: bolder;
    }


    .table-3 .features-3 li{
        list-style: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .features-3 li .list-name{
        font-size: 17px;
        font-weight: 400;
    }

    .features-3 li .icon{
        font-size: 15px;
    }

    .features-3 li .icon.check{
        color: #bc0000;
    }

    .features-3 li .icon.cross{
        color: #cd3241;
    }

    .table-3 .btn{
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    .table-3 .btn button{
        /* width: 73%; */
        padding: 8px 29px;
        /* height: 50px; */
        font-weight: 700;
        color: #fff;
        font-size: 20px;
        border: none;
        outline: none;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
    }


    .Ultimate .price-area-3,
    .Ultimate .inner-area-1{
        background: #bc0000;
    }

    .Ultimate .btn button{
        background: #fff;
        color: #bc0000;
        border: 2px solid #bc0000;
    }

    .Ultimate .btn button:hover{
        /* border-radius: 6px; */
        background: #bc0000;
        color: #fff;
    }


    .Ultimate .package-name-3{
        background: #bc0000;
    }




    .Ultimate ::selection,
    .Ultimate .price-area-3,
    .Ultimate .inner-area-1{
        background: #bc0000;
    }

    .dash-a16{


        width: 100%;
        display: flex;
        justify-content: center;

        
    }





    @media (max-width: 600px){


    .head-dash {
        padding: 47px 0px 0px 0px;

    }

    }


  



    .dash-h12{
        font-size: 26px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding: 20px 0px;
    }

  

    .main-container {
        padding: 30px;
    }
   
    
    .heading {
        text-align: center;
    }
    
    .heading__title {
        font-weight: 600;
    }
    
    .heading__credits {
        margin: 10px 0px;
        color: #888888;
        font-size: 25px;
        transition: all 0.5s;
    }
    
    .heading__link {
        text-decoration: none;
    }
    
    .heading__credits .heading__link {
        color: inherit;
    }
    
    /* CARDS */
    

    
    /* CARD BACKGROUNDS */
    
    .card-1 {
        background: #bc0000;
    }
    
    .card-2 {
        background:  #bc0000;
    }
    
    .card-3 {
        background:  #bc0000;
    }
    
    .card-4 {
        background:  #bc0000;
    }
    
    .card-5 {
        background:  #bc0000;
    }
    
    /* RESPONSIVE */
    
    @media (max-width: 1600px) {
        .cards {
        justify-content: center;
        }
    }
    .h2-im-n{
        color: #bc0000;
    }
    .emi-time-line-section{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;;
    }


    .c1a{
        width: 100%;
        padding: 20px 0px;
        display: flex;
        justify-content: center;
    }
    .c2a{
        width: 70%;
     
    }
    .a1-h1>h1{
    padding: 5px 0px;
        font-size: 18px;
        font-weight: 500;
        color: #bc0000;
    }
    .bb-svg{
        color: #bc0000;
    
    }
    .a1-h2>h1{
    
            font-size: 18px;
            font-weight: 500;
        }
        .cad-ul{
            list-style-type: disc !important;
            padding: 10px 30px;
        }
        .cad-ul>li{
            color:rgb(72, 72, 72);
            line-height: 2;
            font-size: 15px;
            text-transform: uppercase;
    
        }
        .mod-card-bb{
          
        
        width: 400px;
        padding: 30px;
        background-color: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        .card-container-h{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1rem;
    
        }
    
    
        @media only screen and (max-width: 1445px){
    
        .c2a {
            width: 90%;
        }
    
    }
    
    
    @media only screen and (max-width: 1445px){
    
        .cad-ul>li {
            color: rgb(72, 72, 72);
            line-height: 2;
            font-size: 12px;
            text-transform: uppercase;
        }
        .a1-h1>h1 {
            padding: 5px 0px;
            font-size: 14px;
            font-weight: 500;
            color: #bc0000;
        }
        .a1-h2>h1 {
            font-size: 14px;
            font-weight: 500;
        }
        .mod-card-bb {
          
            width: 362px;
            padding: 12px;
    
         
        }
        
    }
    .curr-ha1{
        font-size: 26px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding: 10px 0px;
    }


    
@media only screen and (max-width: 370px){

    .mod-card-bb {
        width: fit-content;
        padding: 12px;
    }
    .btn-act2 {
        padding: 6px 15px;
     
    }
    .btn-2-a {
        color: white;
        background-color: #bc0000;
        border: 1px solid;
        padding: 6px 15px;
        font-size: 15px;
        border: 1px solid white;
    }
    }   
