.v-log-video-c{
    width: 304px !important;
    height: 170px;
}
@media only screen and (max-width: 1099px){



.card__content{
    padding: 10px  0px ;
}
.blog-a3 {
    width: 100% !important;
    display: flex;
    justify-content: center;
}
.blog-card-v {
    border-radius: 20px !important;
    width: 426px;
   
}
}
@media only screen and (max-width: 1100px){
    .blog-a3 {
        width: 100% !important;
    }
    }
    .blog-a3 {
      width: 60%;
    }
    
    
    
  
  