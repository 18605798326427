// {swiper}
  .swiper {
    width: 100%;
    height: 70vh;
    // overflow: visible;
  }
  

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    // background: white;
    transition: all .3s ease;
    
    &.swiper-slide-active {
      // background: linear-gradient(135deg, #000000e6, #000000);
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
      transform: scale(1.4);

      z-index: 2
    }
  }
  
  /* ARROWS */
 
  /* PAGINATION */
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color:#000;
    opacity: 1;
    background: rgba(0,0,0,0.2);
    
    &.swiper-pagination-bullet-active {
      color:#fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
    }
  }
  

  

  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: black ;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  color: black;
}
@media only screen and (max-width: 900px) {


.swiper {
  width: 100%;
  // height: 280px;
  overflow: hidden;
}
.img-g {
  padding: 0px !important;

}

  .swiper-border-1>h1 {
    cursor: pointer;
    font-size: 12px;
}
.swiper-border-1>h1 {
  width: 170px;
}
.swiper-border-1>p {
  font-size: 10px;
}
.episode-p-h-2 {
  width: 90%;
}
.swiper-border-1 {
  border: 1px solid;
  display: flex;
  gap: 1rem;
  padding: 8px 7px;
  flex-direction: column;
  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  align-items: center;
}

}




@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$circleSize: 165px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255,255,255,.35);
$fontColor: rgb(250,250,250);

.profile-pic {
  width: fit-content;
  color: transparent;
  transition: all .3s ease;
  @include object-center;
  position: relative;
  transition: all .3s ease;
  
  input {
    display: none;
  }
  
  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 0;
    
    border: 3px solid #fff;
    max-width: 170px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  }
  
  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }
  
  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0,0,0,.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color .2s ease-in-out;
      border-radius: $radius;
      margin-bottom: 0;
    }
  }
  
  span {
    display: inline-flex;
    padding: .2em;
    height: 2em;
    gap: 10px;
  }
}

/////////////////////////
// Body styling 🐾
/////////---------->


              // Emi details dashboard


              $primary-color: #bc0000;
              $primary-color-hover: scale-color($primary-color, $lightness: 32%);
       
.example-header {
  background: #3D4351;
  color: #FFF;
  font-weight: 300;
  padding: 3em 1em;
  text-align: center;
  h1 {
      color: #FFF;
      font-weight: 300;
      margin-bottom: 20px
  }
  p {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 700;
  }
}
.container-fluid {
  .row {
      padding: 0 0 4em 0;
      &:nth-child(even) {
          background: #F1F4F5;
      }
  }
}

.example-title {
  text-align: center;
  margin-bottom: 60px;
  padding: 3em 0;
  border-bottom: 1px solid #E4EAEC;
  p {
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    /* max-width: 400px; */
    padding: 15px
  }
}

/*==================================
  TIMELINE
==================================*/

  /*-- GENERAL STYLES
  ------------------------------*/
  .timeline {
      line-height: 1.4em;
      list-style: none;
      margin: 0;
      padding: 0;
  
    padding: 30px 0px 0px 0px;


  }

  /*----- TIMELINE ITEM -----*/

  .timeline-item {
      padding-left: 40px;
      position: relative;
      &:last-child {
          padding-bottom: 0;
      }
  }

  /*----- TIMELINE INFO -----*/

  .timeline-info {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 3px;
      margin: 0 0 .5em 0;
      text-transform: uppercase;
      white-space: nowrap;
  }
  /*----- TIMELINE MARKER -----*/

  .timeline-marker {
      position: absolute;
      top: 0; bottom: 0; left: 0;
      width: 15px;
      &:before {
          background: $primary-color;
          border: 3px solid transparent;
          border-radius: 100%;
          content: "";
          display: block;
          height: 15px;
          position: absolute;
          top: 4px;
           left: -3px;
          width: 15px;
          transition: background 0.3s ease-in-out,
                  border 0.3s ease-in-out;
      }
      &:after {
          content: "";
          width: 3px;
          background: #CCD5DB;
          display: block;
          position: absolute;
          top: 24px; bottom: 0; left: 6px;
      }
      .timeline-item:last-child &:after {
          content: none;
      }
  }
  .timeline-item:not(.period):hover .timeline-marker:before {
      background: transparent;
      border: 3px solid $primary-color;
  }

  /*----- TIMELINE CONTENT -----*/

  .timeline-content {
      padding-bottom: 40px;
      p:last-child {
          margin-bottom: 0;
      }
  }

  /*----- TIMELINE PERIOD -----*/
  
  .period {
      padding: 0;
      .timeline-info {
          display: none;
      }
      .timeline-marker {
          &:before {
              background: transparent;
              content: "";
              width: 15px;
              height: auto;
              border: none;
              border-radius: 0;
              top: 0;
              bottom: 30px;
              position: absolute;
              border-top: 3px solid #CCD5DB;
              border-bottom: 3px solid #CCD5DB;
          }
          &:after {
              content: "";
              height: 32px;
              top: auto;
          }
      }
      .timeline-content {
          padding: 40px 0 70px;
      }
      .timeline-title {
          margin: 0;
      }
  }

  /*----------------------------------------------
      MOD: TIMELINE SPLIT
  ----------------------------------------------*/

      .timeline-split {
          @media (min-width: 768px) {
              .timeline {
                  display: table;
              }
              .timeline-item {
                  display: table-row;
                  padding: 0;
              }
              .timeline-info,
              .timeline-marker,
              .timeline-content,
              .period .timeline-info {
                  display: table-cell;
                  vertical-align: top;
              }
              .timeline-marker {
                  position: relative;
              }
              .timeline-content {
                  padding-left: 30px;
              }
              .timeline-info {
                  padding-right: 30px;
              }
              .period .timeline-title {
                  position: relative;
                  left: -45px;
              }
          }
      }

  /*----------------------------------------------
      MOD: TIMELINE CENTERED
  ----------------------------------------------*/

      .timeline-centered {
          @extend .timeline-split;
          @media (min-width: 992px) {
              &,
              .timeline-item,
              .timeline-info,
              .timeline-marker,
              .timeline-content {
                  display: block;
                  margin: 0;
                  padding: 0;
              }
              .timeline-item {
                  padding-bottom: 40px;
                  overflow: hidden;
              }
              .timeline-marker {
                  position: absolute;
                  left: 50%;
                  margin-left: -7.5px;
              }
              .timeline-info,
              .timeline-content {
                  width: 50%;
              }
              > .timeline-item:nth-child(odd) .timeline-info {
                  float: left;
                  text-align: right;
                  padding-right: 30px;
              }
              > .timeline-item:nth-child(odd) .timeline-content {
                  float: right;
                  text-align: left;
                  padding-left: 30px;
              }    
              > .timeline-item:nth-child(even) .timeline-info {
                  float: right;
                  text-align: left;
                  padding-left: 30px;
              }
              > .timeline-item:nth-child(even) .timeline-content {
                  float: left;
                  text-align: right;
                  padding-right: 30px;
              }
              > .timeline-item.period .timeline-content {
                  float: none;
                  padding: 0;
                  width: 100%;
                  text-align: center;
              }
              .timeline-item.period {
                  padding: 50px 0 90px;
              }
              .period .timeline-marker:after {
                  height: 30px;
                  bottom: 0;
                  top: auto;
              }
              .period .timeline-title {
                  left: auto;
              }
          }
      }

  /*----------------------------------------------
      MOD: MARKER OUTLINE
  ----------------------------------------------*/
      
      .marker-outline {
          .timeline-marker {
              &:before {
                  background: transparent;
                  border-color: $primary-color;
              }
          }
          .timeline-item:hover .timeline-marker:before {
              background: $primary-color;
          }
      }
               
             

             

                
// 


// Hexagon dashboard css



#hexGrid {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: sans-serif;
    list-style-type: none;
    font-size: 14px;
  }
  
  .hex {
    position: relative;
    visibility:hidden;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    transition: all 0.5s;
    backface-visibility: hidden;
    will-change: transform;
    transition: all 0.5s;
  }
  .hex::after{
    content:'';
    display:block;
    padding-bottom: 86.602%;  /* =  100 / tan(60) * 1.5 */
  }
  .hexIn{
    position: absolute;
    width:96%;
    padding-bottom: 110.851%; /* =  width / sin(60) */
    margin: 2%;
    overflow: hidden;
    visibility: hidden;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
    -webkit-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
        -ms-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
            transform: rotate3d(0,0,1,-60deg) skewY(30deg);
      transition: all 0.5s;
  }
  .hexIn * {
    position: absolute;
    visibility: visible;
    outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
  }
  .hexLink {

    display: flex;
    align-items: center;
    justify-content: center;

      // display:block;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      overflow: hidden;
      -webkit-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
          -ms-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
              transform: skewY(-30deg) rotate3d(0,0,1,60deg);
  }
  
  
  
  
  /*** HEX CONTENT **********************************************************************/
  .hex img {
    left: -100%;
    right: -100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
  
  .hex h1, .hex p {
    width: 100%;
    padding: 5%;
    box-sizing:border-box;
    font-weight: bold;
    // opacity: 0;
  }
  
  #demo1 {
    color: #F5CE95;
    text-transform: capitalize;
      text-align: center;
    bottom: 50%;
    padding-top:50%;
    font-size: 1.5em;
    z-index: 1;
  }
  .hex h1:before, .hex h1:after {
    display: inline-block;
    margin: 0 0.5em;
    width: 0.25em;
    height: 0.03em;
    background: #ffffff;
    content: '';
    vertical-align: middle;
    transition: all 0.3s;
    text-align:center;
  }
  #demo2 {
    // top: 30%;
    text-align: center;
    text-transform: uppercase;
        
    } 
  #demo3 {
    // font-size: 10px;
  // top: 20%;
  text-align: center;
  text-transform: uppercase;
      
  }
  
  .hexa-im {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #bc0000;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  
  .hexa-im:before, .hexa-im:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    // opacity: 0;
    transition: opacity 0.5s;
  }

  
  /*** HOVER EFFECT  **********************************************************************/
  
  
  
  .hexLink:hover h1, .hexLink:focus h1,
  .hexLink:hover p, .hexLink:focus p{
  opacity:1;
  transition: 0.8s;
  }
  

  
  /*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
  @media (min-width:1201px) { /* <- 5-4  hexagons per row */
    #hexGrid{
      padding-bottom: 4.4%
    }
    .hex {
      width: 20%; /* = 100 / 5 */
    }
    .hex:nth-child(9n+6){ /* first hexagon of even rows */
      margin-left:10%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 1200px) and (min-width:901px) { /* <- 4-3  hexagons per row */
    #hexGrid{
      padding-bottom: 5.5%;
      font-size: 13px;
    }
    .hex {
      width: 25%; /* = 100 / 4 */
    }
    .hex:nth-child(7n+5){ /* first hexagon of even rows */
      margin-left:12.5%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 900px) and (min-width:701px)
   { /* <- 3-2  hexagons per row */
    #hexGrid{
      padding-bottom: 7.4%;
      font-size: 14px;
    }
    .hex {
      width: 33.333%; /* = 100 / 3 */
    }
    .hex:nth-child(5n+4){ /* first hexagon of even rows */
      margin-left:16.666%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 700px) { /* <- 2-1  hexagons per row */
    #hexGrid{
      padding-bottom: 11.2%;
      font-size: 12px;
    }
    .hex {
      width: 50%; /* = 100 / 3 */
    }
    .hex:nth-child(3n+3){ /* first hexagon of even rows */
      margin-left:25%;  /* = width of .hex / 2  to indent even rows */
    }
  }
  
  @media (max-width: 500px) {
      #hexGrid {
          font-size: 12px;
      }
      #demo3 {
        text-align: center;
        text-transform: uppercase;
        font-size: 9px;
    }
  
    
   
  }
  .example-split{
    display: flex;
    flex-direction: column;
    align-items: center;

  }




  // blog scss




  .blog-card-1{
    display: flex;
    padding: 100px 0px ;
  }
  
  
  @mixin mediaBig {
    @media (min-width: 1100px) {
      @content;
    }
  }
  
  @include mediaBig {
    :root {
      --font-size-title: 40px;
    }  
  }
  .video-i-1>video{
    border-radius: 15px;
  }
  .blog-card-v{
    border-radius: 20px !important;
    width: 620px;

    border-radius: var(--border-radius-primary);
    box-shadow: 24px 24px 80px rgba(0,0,0,0.1);
    padding: 20px 20px 28px 20px;
    box-sizing: border-box;
    margin: 20px;
    display: flex;
    flex-direction: column;
    
    @include mediaBig {
      flex-direction: row;
      align-items: center;
      margin: 40px;
      padding: 32px;
    }
  }
  
  .card__image {
    width: 100%;
    max-height: 300px;
    border-radius: var(--border-radius-primary);
    object-fit: cover;
    margin-bottom: 18px;
    
    @include mediaBig {
      width: 45%;
      max-height: none;
      min-height: 400px;
      margin-bottom: 0;
    }
  }
  
  .card__content {
    @include mediaBig {
      width: 55%;
      padding-left: 40px;
    }
  }
  
  .card__date {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-caption);
    line-height: var(--line-height-caption);
    text-transform: uppercase;
    color: var(--color-text);
    margin-bottom: 6px;
    
    @include mediaBig {
      margin-bottom: 8px;
    }
  }
  
  .card__title {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    color: var(--color-text);
    box-decoration-break: clone;
    background-image: linear-gradient(90deg, var(--color-highlight-primary), var(--color-highlight-secondary));
    background-size: 100% 42%;
    background-repeat: no-repeat;
    background-position: 0 85%;
    padding: 0 4px;
    margin-left: -4px;
    
    font-size: 24px;
  
  }
  @media only screen and (max-width: 1100px){
  .blog-a3 {
      width: 100% !important;
  }
  }
  .blog-a3 {
    width: 60%;
  }
  
  
  




  // 


  // case study section 
  












  // 