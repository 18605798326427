
.pdfflip{
    width: 100%;
display: flex;
justify-content: center;
padding-bottom: 100px;
overflow-y: hidden;
flex-direction: column;
align-items: center;
}


.pdfflip::-webkit-scrollbar{
display: none;
}


.demoPage img{
height: 100%;
    width: 100%;

}


.imageScrollCaseStudy{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageScrollCaseStudy img{
    width: 60vw;
}
.imgg-case-ss{
    width: 100%;
    padding: 100px 0px 0px 0px;  
}
.section1-im1{
    /* height: 418px; */

    padding: 100px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}




.swiper-ddd{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}.swiper-slide > img {
    width: 250px;
    border-radius: 15px;
}

@media only screen and (max-width: 600px) {
    .case-img-s{
        width: 70vw !important;
    }
}
.case-img-s{
    width: 50vw;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}