.kWjcRA {
    background-color: #bc0000 !important;
    color:white ;
}

.sc-gswNZR>svg {
color: white;
}
.cdtnjx {
color: white !important;
/* top: 10px !important;
right: 30px !important; */
position: unset !important;

}
.prjCa {
    width: 225px !important;
    height: 100vh;
    padding: 24px;
    position: relative;
    background-color: #bc0000 !important;
    color: white;
}

.fgZRAj {
    color: white !important;
    /* right: -37px !important; */
    position: unset !important;
}

.tooltip {

    position:relative;
  
    /* text-align:left; */
  
  }
  
  .tooltip h3 {margin:12px 0;}
  
  .tooltip .right {

    top: 50%;
    left: 100%;
    margin-left: 35px;
    transform: translate(0, -50%);
    padding: 0;
    color: #EEEEEE;
    background-color: #444444;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgb(0 0 0 / 50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
  }
  
  .tooltip:hover .right {
    visibility:visible; opacity:1;
  }
 
  .tooltip .text-content {
    padding: 1px 15px;
  }
  
  .tooltip .right i {
    position:absolute;
    top:50%;
    right:100%;
    margin-top:-12px;
    width:12px;
    height:24px;
    overflow:hidden;
  }
  .tooltip .right i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:0;
    top:50%;
    transform:translate(50%,-50%) rotate(-45deg);
    background-color:#444444;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
  }
  
  .tool-t{
    display:none;
  }