.bg-term{background-image: url("../../Img/20210204_a547.jpg");
background-position: center;
background-size: cover;
/* min-height: 618px; */

}

.term-a1{
    padding:100px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.term-a2{
    width: 90%;
}


.term-h1{
font-size: 25px;
text-transform: uppercase;
text-align: center;
}

.term-h2{
    font-size: 25px;
    text-transform: uppercase;
 padding: 10px 0px;

    }
    .term-a3{
        padding: 10px 0px;
    }
.term-p{

    color: #595959 ;
    font-size: 17px;
}
@media only screen and (max-width: 800px) {
 
    .about-pp2 {
      font-size: 15px;
      /* text-align: center; */
    }
  
    .about-pp21 {
      text-align: center;
    }
  }