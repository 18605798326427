.bg-module{

    background-image: url('../../Img/20210204_a547.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    /* min-height: 100vh */
}
.module-div {
  display: flex;
  width: 100%;
}
.module-a2 {
  background-image: url("../../Img/bg-module.jpg");
  background-position: center;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: flex-end;
  padding: 20px;

  /* padding: 20px */
}
.module-a1 {
  /* width: 90%; */
  padding: 50px 0px 0px 0px;
}

.module-h1 {
  color:black;

  font-size: 30px;

  text-transform: uppercase;
  padding: 10px 0px;
}
.module-h2 {
    color:black;

  text-transform: uppercase;
  font-size: 15px;
}


.module-a4{
    width: 100%;
    display: flex;
    justify-content:flex-start;
}
.module-a5{
    width: 65%;
    padding: 10px 25px 60px 25px;
  
    margin-top: 20px;
}
.module-h3{
    font-size: 15px;
    color:#313337;
    font-weight: 100;

}
.module-h4{
    font-size: 15px;
    color:#313337;
    font-weight: 100;


}



  .module-a6{
    
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;

    padding: 17px;
 
    background-color: #4444443d;
    justify-content: space-between;
  }
  
  
  
  .progress-value-Modules{

      animation: load 3s normal forwards;
      box-shadow: 0 10px 40px -10px #fff;
      border-radius: 100px;
      background: #bc0000;
      height: 4px;
      width: 0;
      transform: translateX(-5px);
    }
    .progress-Modules{

        background: black;
        justify-content: flex-start;
        border-radius: 100px;
        align-items: center;
        position: relative;
        padding: 0 5px;
        display: flex;
        height: 4px;
        width: 200px;
    }
    .module-a7{
        padding: 30px 0px ;
    }
    .module-a8{
        padding: 10px 10px ;
    }
    .module-a8>p{ 
        font-size: 18px;
        font-weight: 100;
    }

    .module-a9{
        padding: 10px 10px ;
    }
    .module-a9>p{ 
        font-size: 18px;
        font-weight: 100;
    }
    .module-a10{
        padding: 10px 10px ;
    }
    .module-a10>p{ 
        font-size: 18px;
        font-weight: 100;
    }


    .module-a11{
        padding: 10px 10px ;
    }
    .module-a11>p{ 
        font-size: 18px;
        font-weight: 100;
    }


    .module-a12{
        padding: 10px 10px ;
    }
    .module-a12>p{ 
        font-size: 18px;
        font-weight: 100;
    }


.module-h5{
    font-size: 15px;
}
.module-h6{
    font-size: 15px;
    padding: 20px 0px;
}
.module-h11{
    font-size: 15px;
    padding: 20px 0px;
}
/* .module-h6{
  
font-size: 19px;
    font-weight: 400;
}
 */


.module-a13{
cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 28px;
    background: #a9a9a94a;
    align-items: center;
}
.module-h7{
cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    text-decoration-line: line-through;

}


.module-a13:hover,.module-h7:hover{
color: #bc0000;

}
.module-a14:hover,.module-h7:hover{
    color: #bc0000;
    
    }
.module-a14{
    cursor: pointer;
    border-radius: 5px;
    gap: 1rem;
    display: flex;
    padding: 28px;
    background: #a9a9a94a;
    align-items: center;
    justify-content: space-between;
}
.module-h8{
cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    /* text-decoration-line: line-through; */
}
.module-h8:hover{
color: #bc0000;

}





.modules-title{

    gap: 1rem;
    display: flex;
    flex-direction: column;
}




        /* card - css */
        .card-SideMoudle{
            width: 300px;
            height: 450px;
            box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
            position: relative; /* fix property */
            overflow: hidden; /* fix property */
            border-radius: 10px;
          }
          
          .card-SideMoudle>img {
            width: 100%;
            object-fit: cover;
            height: 65%;
        }
          
          .card-description {
            position: absolute; /* fix property */
            bottom: 0;
            left: 0;
            right: 0;
            background-color:white;
            padding: 10px;

          }
          .module-a20{

              /* position: fixed; */
              /* left: 0; */
              /* top:100px; */
              /* right: 0px; */
              margin: 10px 32px;
            }
            .btn-1-Modules{
           
                padding: 8px 21px;
                border-radius: 15px;
                margin: 5px 0px;
                color: white;
                background-color: #bc0000;
            
            }
            .section-1-module{
                display: flex;
                width: 100%;
            }
            .module-h15{
                font-size: 15px;
                text-align: center;
                padding: 10px;
            }
            .card-description-d1{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
            }
            .card-description-d2{
                padding: 15px;
                            }
/* media query section */

    @media only screen and (max-width: 1100px) {

        .module-a020 {
           
            width:unset !important;

          
            }
        .module-a20 {
      position: unset !important;
        }
        .section-1-module {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }
        .module-a5 {
            width: 80%;
            padding: 10px 25px 60px 25px;
            height: unset !important;
            overflow-y: unset !important;
        }
        .module-a4 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
        .section-1-module {
            display: flex;
            flex-direction: column-reverse;
            align-content: center;
            align-items: center;
        }
        .module-a6 {
gap: unset !important;
        }
    }
    @media only screen and (max-width: 600px) {
        .module-div {
            
        display: unset !important;
        }
        .module-a8>p {
        
        font-size: 15px;
        }
        .module-a9>p {
        
            font-size: 15px;
            }
            .module-a10>p {
        
                font-size: 15px;
                }
                .module-a11>p {
        
                    font-size: 15px;
                    }
                    .module-a12>p {
        
                        font-size: 15px;
                        }
                        .module-a2 {
                   
                            background-position: center;
                            background-size: cover;
                            height: 150px;
                            display: flex;
                            align-items: flex-end;
                            padding: 10px;
                          
                            /* padding: 20px */
                          }
                          .module-h1 {
                      
                            font-size: 20px;
                            padding: 4px 0px !important;
                      
                        }
                        .module-h2 {
                        
                            font-size: 15px;
                        }

                        .module-a20 {
                         margin: unset !important;
                        }
                        .module-a5 {
                          
                            padding: 10px 10px 10px 10px;
                            height: 100vh;
                            overflow-y: scroll;
                            margin-top: 20px;
                        }
        }

.module-a020{

    width: 35%;
        display: flex;
        justify-content: center;
    }


/*     
.container-s{
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 70% 30%; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "content sidecard"; 
  }
  .content { grid-area: content; }
  .sidecard { grid-area: sidecard; } */


.dec-h1{

    font-size: 15px;
    text-transform: uppercase;
}

.dec-h2{
    font-weight: 500;
    font-size: 15px;
padding: 5px 0px;
}
.card-description-d3{
padding: 10px 0px;
}




.span-cer1{

    
    display: flex;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 360px){

    .module-a4 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .card-SideMoudle {
        width: 249px;
        height: 380px;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }
    .module-h15 {
        font-size: 15px;
        text-align: center;
        padding: 1px;
    }
    .card-description-d2 {
        padding: 5px;
    }
    
}

@media only screen and (max-width: 450px){
    .module-a3 {
        display: none;
    }
}