.case-c {
    background-image: url("../../Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  
  .contact-h1 {
    font-size: 20px;
  }
  .contact-h2 {
    font-size: 20px;
  }
  .contact-h3 {
    font-size: 20px;
  }
  .contact-section1 {
    /* height: 418px; */
   
  
    padding: 100px 0px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .head-pp {
    width: 90%;
  }
  .contact-section2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  
    gap: 3rem;
  }
  /* .load{
      border: 16px solid black;
      border-radius: 50%;
      border-top: 16px solid white;
      width: 120px;
      height: 120px;
      animation:  rotate 2s linear infinite;
  
  }
  @keyframes rotate{
      0%{transform: rotate(0deg);}
      100%{transform: rotate(360deg);}
  
  
  } */
  
  .login-box {
    /* position: absolute; */
    /* top: 60%; */
    /* left: 50%; */
    width: 400px;
    padding: 40px;
    /* transform: translate(-50%, -50%); */
    background: #bc0000;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    border-radius: 10px;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  
    text-transform: uppercase;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color:white;
    font-size: 12px;
  }
  
  .login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color:white;
  
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 40px;
    letter-spacing: 4px;
  }
  
  /* .login-box a:hover {
    background:white;
    color:black;
    border-radius: 5px; */
    /* box-shadow: 0 0 5px#bc0000, 0 0 25px#bc0000, 0 0 50px#bc0000, 0 0 100px#bc0000; */
  /* } */
  /* 
  .login-box a span {
    position: absolute;
    display: block;
  }
  
  .login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, white);
    animation: btn-anim1 1s linear infinite;
  }
  
  @keyframes btn-anim1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  
  .login-box a span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent,white);
    animation: btn-anim2 1s linear infinite;
    animation-delay: 0.25s;
  }
  
  @keyframes btn-anim2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  
  .login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, white);
    animation: btn-anim3 1s linear infinite;
    animation-delay: 0.5s;
  }
  
  @keyframes btn-anim3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  
  .login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, white);
    animation: btn-anim4 1s linear infinite;
    animation-delay: 0.75s;
  }
  
  @keyframes btn-anim4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  } */
  
  .contact-us-form {
    display: flex;
    justify-content: center;
    /* padding: 30px 0px; */
  }
  .user-box>textarea{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    box-sizing: border-box;
  
  }
  .user-box>textarea:focus{
    border: none;
    outline-width: 0;
    resize: none;
  }
  .submit-btn-contact{
  
  background-color: white;
  border: 1px solid #bc0000;
  padding: 10px 14px;
  margin-top: 15px;
  color: #bc0000;
  font-size: 17px;
  text-transform: uppercase;
  border-radius: 5px;
  
  }
  .submit-btn-contact:active{
    transform: scale(0.9);
    transition: all 0.5;
  }
  .div-btn-sub{
  
    display: flex;
    justify-content: center;
  }
  .user-box input:focus {
    background-color: transparent;
  }
  
  .user-box>input[type="number"] {
      appearance: none !;
  }








  .contact-us-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .box{
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    margin: 0 auto;
  }
  .card{
    cursor: pointer;
    position: relative;
    width: 300px;
    height: 350px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
  }
  .card:before,
  .card:after
  {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    transition: 0.5s;
    z-index:-1;
  }

  
  .card .imgBx{
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
  }
  


  .card .imgBx img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
     
  }

  .card .details{
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      height: 60px;
      text-align: center;
  }

  .card .details h2{
   margin: 0;
   padding: 0;
   font-weight: 600;
   font-size: 20px;
   color: #777;
   text-transform: uppercase;
  } 

  .card .details h2 span{
  font-weight: 500;
  font-size: 16px;
  color: #f38695;
  display: block;
  margin-top: 5px;
   } 



   /* card */


   article {
    --img-scale: 1.001;
    --title-color: black;
    --link-icon-translate: -20px;
    --link-icon-opacity: 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 16px;

    background: #fff;
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
  }
  
  article a::after {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    cursor: pointer;
    content: "";
  }
  
  /* basic article elements styling */
  article h2 {
    margin: 0 0 18px 0;
    font-family: "Bebas Neue", cursive;
 
    color: var(--title-color);
    transition: color 0.3s ease-out;
  }
  
  figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 16;
    overflow: hidden;
  }
  
  article img {
    max-width: 100%;
    transform-origin: center;
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
  }
  
  .article-body {
    padding: 12px;
  }
  
  article a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #bc0000;
    text-transform: capitalize;
  }
  
  article a:focus {
    outline: 1px dotted #bc0000;
  }
  
  article a .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
  }
  
  /* using the has() relational pseudo selector to update our custom properties */
  article:has(:hover, :focus) {
    --img-scale: 1.1;
    --title-color: #bc0000;
    --link-icon-translate: 0;
    --link-icon-opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  
  /************************ 
  Generic layout (demo looks)
  **************************/
  

  
  .articles {
    display: grid;
   width: 300px;


    gap: 24px;
  }
  
  @media screen and (max-width: 960px) {
    article {
      container: card/inline-size;
    }
    .article-body p {
      display: none;
    }
  }
  
  @container card (min-width: 380px) {
    .article-wrapper {
      display: grid;
      grid-template-columns: 100px 1fr;
      gap: 16px;
    }
    .article-body {
      padding-left: 0;
    }
    figure {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    figure img {
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }
  
  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }


   /*  */

   /* box-sizing: border-box; */
.section-case-study{
  padding: 100px 0px;
}
.sc-11{
width: 100%;
display: flex;
justify-content: center;
}
.sc-12{
  width: 90%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}
  .article-body>p{
    font-size: 13px;

  }
  .heading-studys{
    color: #bc0000;
    padding: 20px 0px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
  }


  @media screen and (max-width: 600px) {


  .section-case-study {
    padding: 54px 0px !important;
}
  }

.cse-h-k{

  
  color: #bc0000;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
    text-transform: uppercase;
    padding: 10px 0px;
  } 