.bDZlYX {
position: unset !important;
display: none !important;

}
.dXVOPX {
    min-width: unset !important; 
    max-width: unset !important; 

}
.downloadLink{
    display: none !important;
}


*{
  user-select: none !important;
}

@media only screen and (max-width: 600px) {
 
    .body-fix-h{
        overflow: unset !important;
        height:  unset !important;
      }
      .main-y{
        overflow-y: unset !;
        width:  unset !important;
      }
    
     
     
      .wrapper {
        display:  unset !important;
        height:  unset !important;
      }

}
.body-fix-h{
  overflow: hidden;
  height: 100vh;
}
.main-y{
  overflow-y: auto;
  width: 100%;
}



.wrapper {
  display: flex;
  height: 100%;
}


.grid-c1 {
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  /* grid-template-rows: 86% 77px; */
  gap: 0px 0px;
  align-content: space-between;
  grid-template-areas:
    "HeadAboutBody"
    "HeadAboutFotter";
    /* overflow-y: hidden; */
    
}

.grid-c2 {
  grid-area: HeadAboutBody;
  overflow-y: scroll;
}
.grid-c3 {
  grid-area: HeadAboutFotter;
  background: white;
  border-top: 1px solid;

  grid-area: HeadAboutFotter;
  position: relative;


}
@media only screen and (max-width: 600px){



  .grid-c1 {
    display: unset !important;
  }
  .grid-c3 {

    background:transparent;
    border-top: unset;
  
    grid-area: HeadAboutFotter;
  
  
  
  }.grid-c2 {
    grid-area: HeadAboutBody;
    overflow-y: unset !important;
  }
  .home-side-bar-data {
    display: unset  !important;
    width: 100%;
  }

}
