.head-about {
    padding: 14px 0px;
  }
  .about-h1 {
    color: #bc0000;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
  }
  .bg-about {
    background-image: url("../../Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
  }
  .w-o {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .w-oo {
    margin-top: 20px;
    width: 80%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
  }
  .para-about2 {
    margin-top: 30px;
    padding-bottom: 98px;
  }
  .about-pp2 {
    line-height: 2;
    font-size: 18px;
    color: #595959;
  }
  .head-about {
    padding: 100px 0px 0px 0px;
  }
  
