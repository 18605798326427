.about-h1 {
  color: #bc0000;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}
.bg-study {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  /* min-height: 618px; */

  height: 100vh;
}

.blog-h2 {
  font-size: 20px;
}
.blog-y {
  display: flex;
  justify-content: center;
}
.blo-logo {
  width: 200px;
}
.blo-kk {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px;
}
.intro-o {
  font-size: 15px;
  padding: 10px 0px;
}
.paf-g {
  width: 100%;

  padding-bottom: 50px;

  display: flex;
  justify-content: center;
}
.blo-int {
  display: flex;
  padding: 20px 20px;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 870px) {
  .blo-int {
    display: flex;
    display: flex;
    padding: 20px;
    gap: 2rem;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.react-tabs__tab {
  bottom: -1px;
}

.tab-oi {
  width: 90%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
}

.tab-1 {
  color: white;
  font-size: 13px;
  /* text-align: center; */
  /* padding: 15px 0px; */
  cursor: pointer;
  line-height: 1.5;
}
.tab-s-p {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
  padding: 10px 0px;
  /* text-align: center; */
  /* margin: 20px; */
}

.sidebar {
  /* display: none ; */
  overflow-y: scroll;
}

.blo-int > p {
  font-size: 16px;
}
.hr-blog {
  border: 0.5px solid white;
}
.svg-open {
  padding: 10px;
  font-size: 30px;
}

@media only screen and (max-width: 1054px) {
  .i-frame-o {
    width: 100% !important;
  }
}
.iframe-w-v {
  width: 100%;
}
.i-frame-o {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fle-main-div {
  display: flex;
  width: 100%;
  justify-content: center;
}
/* Follow me for more pens like this! */

/* Tweak to change the look and feel */
:root {
  --dropdown-highlight: dodgerblue;
  --dropdown-width: 160px;
  --dropdown-background: white;
  --dropdown-color: black;
}
.menu-modules {
  top: 80px;
  position: fixed;
}

.tabs-1 {
  padding: 100px 0px 0px 0px;
}
.accordion__heading {
  padding: 10px 0px;
}

.lessons-a1 > h1 {
  font-size: 20px;
  padding: 10px 0px;
  /* font-weight: 400; */
}
.span-1-lessons {
  color: #bc0000;
  cursor: pointer;
}

.lessons-a2 > h1 {
  font-size: 15px;
  padding: 10px 0px;
  font-weight: 400;
}
.lessons-a3 > h1 {
  font-size: 20px;
  padding: 10px 0px;
  /* font-weight: 400; */
}
.lessons-a4 > h1 {
  font-size: 15px;
  padding: 10px 0px;
  font-weight: 400;
}

.sidebar-a1 {
  padding: 10px;
}
.progress-bar-a1 {
  padding: 10px 0px;
}
.head-h > img {
  width: 170px;
}
.head-h {
  display: flex;
}
.close-svg1 {
  color: black;
  font-size: 20px;
}
.close-div-svg {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
.side-mod1 {
  font-size: 13px;
  padding: 4px 0px;
}
.side-mod2 {
  font-size: 13px;
  padding: 4px 0px;
}
.side-mod3 {
  padding: 10px 0px;
}
.side-m-h1 {
  font-size: 15px;
}
.module-title-2 {
  padding: 10px 0px;
}
.module-side-a1 {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #a9a9a94a;
  cursor: pointer;
  align-items: center;
}
.module-side-h1 {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  /* text-decoration-line: line-through; */
  color: black;

  text-transform: uppercase;
}
.module-side-h1:hover {
  color: #bc0000;
}
.module-side-a2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px 0px;
}
.head-side-modules {
  overflow-y: scroll;
  height: 100%;
}

.i-f-1 {
  max-width: 100%;
  height: 533px;
}

@media only screen and (max-width: 900px) {
  .i-f-1 {
    max-width: 100%;
    height: 250px;
  }
  .lessons-b1 {
    text-align: center;
  }
  .i-a3 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .menu-modules {
    position: unset !important;
  }
  .tabs-1 {
    padding: 61px 0px 0px 0px;
  }
  .i-f-1 {
    max-width: 100%;
    height: 200px;
  }
}

.i-a3 {
  width: 60%;
}

.tab-list-modules {
  display: flex;
  gap: 2rem;
  /* padding: 10px 20px; */
}
.tab-panel-m1 {
  display: flex;
  padding: 20px 20px;

  flex-direction: column;
  align-items: center;
}

.span-m1 {
  font-size: 17px;
  display: flex;
  gap: 10px;

  padding: 10px 0px;
  cursor: pointer;
}
.span-m2 {
  cursor: pointer;

  font-size: 17px;
  display: flex;
  gap: 10px;

  padding: 10px 0px;
}
.a1 {
  border-bottom: 1px solid red;
}
.a2 {
  border-bottom: transparent;
}

.module-ah1 {
  padding: 12px 0px;
  font-size: 14px;
}
.mod-a1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.span-mod1 {
  border: 1px solid;
  padding: 10px 16px;
  background: #1cd991;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}
.mod-a2 {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  .mod-a1 {
    display: flex;

    align-items: center;
    flex-wrap: wrap;
  }
}

.prev1 {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.next1 {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}
.tool-b1 {
  --plz-color-black-2: #212529;
  --plz-color-white: white;
  --plz-border-radius-default: 0.25rem;
  --scale: 0;
  --arrow: 5px;

  display: flex;
  padding: 10px;

  justify-content: flex-end;
  display: flex;
  gap: 2px;
}

.tooltip1 {
  position: relative;
  font-size: 20px;
  /* padding: 0.5em; */
  border-radius: 0.2em;
  box-sizing: border-box;
}

.tooltip1::before,
.tooltip1::after {
  position: absolute;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 200ms transform;
  transform-origin: bottom center;
  top: -0.25rem;
  left: 50%;
}

.tooltip1::before {
  --translate-y: calc(
    -100% - var(--arrow)
  ); /* Para controlar la flecha del tooltip */
  background-color: var(--plz-color-black-2);
  color: var(--plz-color-white);
  text-align: center;
  border-radius: var(--plz-border-radius-default);
  content: attr(data-tool);
  padding: 0.5rem;
  /* Esto hará que el texto se distribuya mejor dentro del espacio */
  width: max-content;
  font-size: 12px;

  /* max-width: 100%; */
}

.tooltip1:hover::before,
.tooltip1:hover::after {
  --scale: 1;
}

.tooltip1::after {
  --translate-y: calc(-1 * var(--arrow));
  transform-origin: top center;
  content: "";
  border: var(--arrow) solid transparent;
  border-top-color: var(--plz-color-black-2);
  border-top-width: 5px;
}
.previous-btn1 {
  padding: 7px 20px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;

  display: flex;
  align-items: center;
  background-color: gray;

  color: white;
}
.previous-btn1:hover {
  background-color: #bc0000;
  color: white;
}
.next-btn1 {
  padding: 7px 20px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;

  display: flex;
  align-items: center;
  background-color: gray;
  color: white;
}
.next-btn1:hover {
  background-color: #bc0000;
  color: white;
}

.tab-panel-2 {
  padding: 20px 0px;
}

.button-24 {
  background:  #bc0000;
  border: 1px solid  #bc0000;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

.span-mod2 {
  cursor: pointer;
  border: 1px solid;
  padding: 10px 16px;
  background: #d91c1c;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}

.span-mod2 {
  cursor: pointer;
  border: 1px solid;
  padding: 10px 16px;
  background: #d91c1c;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}

.span-mod2:hover {
  transform: scale(1.05);
  transition: ease-in-out 0.4s;
  cursor: pointer;
  border: 1px solid;
  padding: 10px 16px;
  background: #d91c1c;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}

.progress {
  background: rgba(0, 0, 0, 0.151);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 8px;
  width: 200px;
}

.progress-value {
  animation: loadd 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value1 {
  animation: loadd1 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value2 {
  animation: loadd2 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value3 {
  animation: loadd3 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value4 {
  animation: loadd4 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value5 {
  animation: loadd5 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value6 {
  animation: loadd6 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value7 {
  animation: loadd7 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}

.progress-value8 {
  animation: loadd8 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}


.progress-value9 {
  animation: loadd9 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(-3px);
}


.progress-value10 {
  animation: loadd10 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #bc0000;
  height: 4px;
  width: 0;
  transform: translateX(0px);
}

@keyframes loadd {
  0% {
    width: 0;
  }
  100% {
    width: 0%;
  }
}

@keyframes loadd1 {
  0% {
    width: 0;
  }
  100% {
    width: 10%;
  }
}

@keyframes loadd2 {
  0% {
    width: 0;
  }
  100% {
    width: 20%;
  }
}

@keyframes loadd3 {
  0% {
    width: 0;
  }
  100% {
    width: 30%;
  }
}

@keyframes loadd4 {
  0% {
    width: 0;
  }
  100% {
    width: 40%;
  }
}

@keyframes loadd5 {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

@keyframes loadd6 {
  0% {
    width: 0;
  }
  100% {
    width: 60%;
  }
}

@keyframes loadd7 {
  0% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}

@keyframes loadd8 {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}
@keyframes loadd9 {
  0% {
    width: 0;
  }
  100% {
    width: 90%;
  }
}
@keyframes loadd10 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.span-mod2:visited {
  background-color: #1cd991;
}



.BonusVideo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
}