.Profile-bg {
  background-image: url("../../Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;

}

.profile-div-1-1 {
  padding: 100px 0px 0px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.profile-div-1-2 {
  width: 90%;
}
.user-profile-name-pic {
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  border: 1px solid #0000006b;
}
.user-name-profile > h1 {
  display: flex;
  flex-direction: column;
  font-weight: initial;
  font-size: 25px;
  /* gap: 5px; */
  line-height: 1.5;
  color: #122b46;
}

.span-1-profile {
  color: #a3a5a9;
  font-size: 15px;
}
.user-profile-data {
  border: 1px solid;
  padding: 15px 31px;
}
.user-profile-name {
  display: flex;
  align-items: center;
  gap: 2rem;
  /* background-color: white; */
  padding: 20px;
}
.tab-list-title {
  display: flex;
  border: 1px solid #0000006b;
  border-top-width: 0px;
  padding: 0px 10px;
  gap: 1rem;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.title-tab-1 {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  padding: 15px 14px;
  color: #122b46;
  /* border-bottom: 1px solid red; */
}

.title-tab-1:hover {
  color: #bc0000;
}
.edit-btn-profile {
  padding: 9px 25px;
  border-radius: 25px;
  background-color: #d9d9d9;
  font-size: 15px;
  color: black;
}
.edit-btn-profile:hover {
  padding: 9px 25px;
  border-radius: 25px;
  background-color: #bc0000;
  font-size: 15px;
  color: white;
}
.tab-panel-1-profile > h1 {
  font-size: 24px;
  font-weight: 500;
}
.tab-panel-1-profile {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
}
.panel-head-1 {
  border: 1px solid #0000006b;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 5px;
}

.tab-1-profile-f:focus {
  border: none !important;
}
.tab-1-profile-f:active {
  border: none !important;
}
.td-profile-1 {
  padding: 20px 0px;
}

.tr-profile-1 {
  display: flex;
  gap: 8rem;
  width: 100%;
}
.tr-profile-1 > td {
  white-space: pre;
}
@media only screen and (max-width: 600px) {
  .tr-profile-1 {
    display: flex;
    gap: unset;
    width: 100%;
    justify-content: space-between;
  }
  .user-profile-name {
    flex-wrap: wrap;
    justify-content: center;
  }
  .user-name-profile > h1 {
    align-items: center;
  }
  .tab-data-5 {

      flex-wrap: wrap;
    }
    .heading-h1-edit>h1 {
  
      font-size: 15px !important;
      text-align: center;
      white-space: pre;

  }
}
.tbody-profile-1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.bb1 {
  border-bottom: 1px solid red;
}
.bb11 {
  border-bottom: transparent;
}

.tab-list-title1 {
    display: flex;
    gap: 2rem;
    padding: 25px 0px;
    flex-wrap: wrap;
    align-items: center;
}
.tabs-2-1 {
  padding-bottom: 60px;
  /* margin-top: 25px; */
}
.h1-tabs-2 {
    padding: 25px 0px;
    font-size: 20px;
    font-weight: 400;
}
.heading-tabs-2 {
    font-size: 20px;
    padding: 10px 20px;
    font-weight: 400;
}
.all-Discussions-tab1 {
  border: 1px solid #0000006b;
  border-radius: 5px;
  background-color: white;
}
.tab-data-5 {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 1rem;

}
.tab-data-5:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.5s;
  }

.tab-data-6-h1 {
  font-size: 18px;
  font-weight: 400;
  color: black;
  cursor: pointer;
}
.tab-data-6-h1:hover {
  color: #bc0000;
}
.tab-data-6-h2 {
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0px;
}
.forum-reply-profile {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #a3a5a9;
}
.profile-span-2:hover {
  color: #bc0000;
}
.profile-span-2 {
  cursor: pointer;
}
.profile-span-3:hover {
  color: #bc0000;
}
.profile-span-3 {
  cursor: pointer;
}
.btn-prep-pro {
  color: white;
  border: 2px solid;
  padding: 7px 9px;
  border-radius: 23px;
  background-color: #bc0000;
}
.btn-prep-pro:hover {
  color: white;
  border: 2px solid;
  padding: 7px 9px;
  border-radius: 23px;
  background-color: #bc0000b6;
}
.tab-data-6{
    width: 100%;
}
.section-1-p{

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.profile-tab-heads{
  padding-bottom:50px ;
}
.profile-edit-setting{
  padding: 40px 40px 12px 40px;
}
.heading-h1-edit>h1{

  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}
.save-change-btn{

  
  padding: 7px 16px;
  border: 1px solid;
  color: white;
  background: #bc0000;
  border-radius: 15px;
}
.header-modal-edit2{

  
  display: flex;
/* width: 100%; */
justify-content: space-between;
align-items: center;
padding: 8px 21px;
/* flex-wrap: wrap; */
gap: 5px;
}
.save-change-btn-div{

  padding: 15px;
  display: flex;
  justify-content: center;
}


.comm-gf2-button{
  background-color: rgba(255, 217, 0, 0.966);
  height: 40px;
  color: rgb(250, 250, 250);
  font-weight: bold;
  width: 80px;
  border-radius: 10px;
  text-transform: capitalize;
}


.comm-gf2-button1{
  background-color: rgba(32, 252, 98, 0.966);
  height: 40px;
  color: rgb(250, 250, 250);
  font-weight: bold;
  width: 80px;
  border-radius: 10px;
  text-transform: capitalize;
}

.comm-gf2-button2{
  background-color: rgba(255, 0, 0, 0.966);
  height: 40px;
  color: rgb(250, 250, 250);
  font-weight: bold;
  width: 80px;
  border-radius: 10px;
  text-transform: capitalize;
}
.border-proff{
  border:1px solid #0000006b;
  border-radius: 5px;
  margin: 10px 0px;
}