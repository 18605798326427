:root{
    --body_background: white;
    --body_color: black;
    --link_color: navy;
}


[data-theme="dark"] {
    --body_background: black;
    --body_color: white;
    --link_color: cyan;
}


*{
    transition: all 0.3s ease-in-out;
}