a {
    text-decoration: none;
    list-style-type: none;
}
.footer-span-1{
    font-size: 15px;
    font-weight: 400;
color: black;

}
.footer-span-2{
    font-size: 15px;
    font-weight: 400;
color: black;
}
.footer-span-3{
    font-size: 15px;
    font-weight: 400;
color: black;
}
.footer-options{
display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px;
}
.footer-div2{
    display: flex;
    gap: 1rem;
}


@media only screen and (max-width: 600px) {
.footer-options {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}
}
.footer-span-2:hover{
color:  #bc0000;
   
}
.footer-span-3:hover{
    color:  #bc0000;
       
    }